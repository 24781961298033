body {
  margin: 0;
}

* {
  font-family: 'Inter', sans-serif;
}

.mulish-font {
  font-family: 'Mulish', sans-serif;
}

.App {
  overflow-x: hidden;
}

.content-container {
  overflow: auto;
}

.f-12 {
  font-size: 12px;
}

.f-20 {
  font-size: 20px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.p-relative {
  position: relative;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.custom-label {
  height: 16px;
  padding: 2px 4px !important;
}

.custom-label p {
  margin: 0;
  padding: 2px 0 !important;
}

.main-font-color {
  color: #00AB92;
}

.secondary-font-color {
  color: #0D175C;
}

.select-item {
  width: 100%;
  font-size: 14px;
}

.select-item.active {
  color: #03ab93;
}

.select-item.active input + div {
  background: #03ab93;
  border-color: #03ab93;
}

.error-message {
  font-size: 12px;
  color: rgb(255,59,48);
  padding-top: 4px;
}

.success-message {
  font-size: 12px;
  color: #00AB92;
  padding-top: 4px;
}

.error-message ul {
  padding: 0.25rem 0 0.5rem 1.5rem;
  margin: 0;
}

.error-landing {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 4px;
  line-height: 1.15rem;
}

.pr-20 {
  padding-right: 20px;
}

.table-head-pr-20 + div {
  padding-right: 20px;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-20 {
  padding-top: 20px;
}

.px-22 {
  padding-left: 22px;
  padding-right: 22px;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.windowScroll {
  height: auto !important;
  max-height: 210px;
}

path {
  fill-rule: evenodd !important;
  clip-rule: evenodd !important;
}

.pointer:hover {
  cursor: pointer;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.form-step-container {
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.form-step-container.form-d-none {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 300ms, opacity 300ms 0ms;
  padding: 0;
}

.break-word-all * {
  word-break: break-word;
}

.page-container {
  padding-left: 22px;
  padding-right: 22px;
}

.item-custom-select {
  height: 32px;
  border-radius: 0 !important;
}

.blur-bg {
  backdrop-filter: blur(5px);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.label-with-information > div > div {
  width: calc(100% - 16px);
}

/* Custom scrollbar */
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 6px;
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	background-color: #D8D8D8;
}

/* Disable arrow in number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

/* Animation keyframe */
.fade-in {
  animation: fadeIn ease-in .1s;
}
@keyframes fadeIn {
  0% { opacity: 1}
  50% { opacity: 1; }
  100% { opacity: 0; }
}

input:focus,
div[role="button"],
div[role="button"]:focus {
  border-width: 1px !important;
}

.flag-count {
  background-color: transparent !important;
  height: unset;
}

.flag-count > p {
  font-size: 14px;
  color: #191927;
  font-weight: normal;
  opacity: 0.5;
}

.select-search {
  border-color: #03AB93 !important;
  box-shadow: unset !important;
}

.select-search:hover {
  border-color: #03AB93;
}

.bottom-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.bottom-arrow::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: rgba(58, 57, 73, 0.96);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
}

.bar::after {
  content: ''
}

.paid-legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 0.5rem;
}

.due-legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 0.5rem;
  background-size: 11px 11px;
  background-image: linear-gradient(135deg, #000 25%, #fff 25%, #fff 50%, #000 50%, #000 75%, #fff 75%, #fff);
}

/* Radio buttons */
.radio-buttons > div {
  display: flex;
}

.radio-buttons.d-block > div {
  display: block;
}

.radio-buttons.d-block > div label {
  flex-wrap: wrap;
}

.radio-buttons > div label:not(:last-of-type) {
  margin-right: 30px;
}

.radio-buttons > div label > div {
  height: 17px;
  margin-right: 10px;
}

.radio-buttons > div label > div > div {
  width: 16px;
  height: 16px;
}

.radio-buttons > div label span {
  font-size: 14px;
  letter-spacing: 0.18px;
}
.radio-buttons > div label input:checked ~ div:after {
  width: 8px;
  height: 8px;
}

.expense-modal {
  width: 512px;
  margin-left: auto;
}

.expense-modal div[aria-hidden="true"] {
  display: none;
}

.expense-modal .modal-spinner {
  background-color: rgba(255, 255, 255, 0.5);
}

.row-action {
  opacity: 1;
  transition: .3s ease;
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  z-index: 2;
}

.row-date {
  opacity: 1;
  transition: .3s ease;
}

.hidden-row-action {
  opacity: 0 !important;
  pointer-events: none;
}

.white-bordered-scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: transparent;
}

.white-bordered-scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: transparent;
}

.white-bordered-scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
	background-color: #dcdbdb;
}

@keyframes spinner-load-bounce {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tree-list-icon {
  padding-right: 6px;
}

.tree-list-icon > * {
  height: 12px;
  width: 12px;
}

.pre-image {
  margin-right: 12px;
}

.drag-placeholder {
  position: absolute;
  border-radius: 3px;
  background-color: #00AB92;
  width: calc(100% - (22px * 2));
  left: 22px !important;
  height: 3px;
}
.drag-placeholder::before {
  left: -8px;
}
.drag-placeholder::after {
  right: -8px;
}
.drag-placeholder::before,
.drag-placeholder::after {
  content: ' ';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid #00AB92;
  transform: translateY(-7px);
  background-color: #fff;
  border-radius: 50%;
}

.error-color {
  color: rgb(255,59,48);
}

.unread-counter {
  display: block;
  padding: 0 !important;
  width: 12px;
  height: 12px;
  min-width: 12px !important;
  top: 0;
  border-radius: 50% !important;
  background-color: #FF0000;
  border: 2px solid #000;
}

.row-reverse {
  flex-direction: row-reverse;
}

.circle-bg-image {
  width: 65px;
  height: 65px;
  background-color: rgba(0, 171, 146, 0.39);
  border-radius: 50%;
  position: relative;
  margin-top: 14px;
  margin-bottom: 18px;
}

.circle-bg-image img {
  max-height: 60px;
  position: absolute;
  left: 16px;
  bottom: 14px;
}

.circle-bg-image::after {
  content: ' ';
}

.icon-person-option {
  position: relative;
  transition: 0.2s ease-in;
}

.icon-person-option:before {
  position: absolute;
  content: ' ';
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 50%;
  width: 100%;
  transition: 0.2s ease-in;
}

.search-container {
  width: 295px;
  margin-bottom: 10px;
}

.search-container input {
  min-height: 34px;
  border-color: #00145A !important;
}

.search-container input:active,
.search-container input:focus {
  border-width: 2px !important;
}

.search-container i {
  color: #00145A
}

.table-container {
  padding: 0 22px 40px 22px;
}

.table-container .table {
  min-height: 500px;
}

.table-container .table .empty-message {
  min-height: calc(434px - 74px);
}

.table-container .row-container {
  background-color: transparent;
  border-top: 2px solid #e2e2e2;
}

.table-container .row-container > div:first-of-type {
  padding-left: 0;
  min-width: 240px;
}


.table-container > div > div > div:first-of-type .row-container {
  border-top: unset;
}

button.darkblue {
  border-color: #0D175C;
  background-color: #0D175C;
  color: #FFF;
}

button.darkblue:hover,
button.darkblue:active {
  border-color: #0D175C;
  background-color: #0D175C;
  color: #FFF;
}

button.outlined-button {
  border-color: #0D175C;
  color: #0D175C;
}

button.outlined-button:hover,
button.outlined-button:active {
  border-color: #0D175C;
  background-color: #0D175C;
}

.not-found-page {
  min-height: calc(100vh - 100px - 44px) !important;
}
